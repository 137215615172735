.top-0 {
    top: 0;
}

.start-0 {
    left: 0;
}

.bottom-0 {
    bottom: 0;
}

.end-0 {
    right: 0;
}

.controll-Unit button {
    width: 12rem;
    height: 3.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: 1rem;
    text-align: left;
}

.sd-center {
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -mos-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.modal-dialog {
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.modal.show {
    justify-content: center;
    align-items: center;
}

.editStudentTable {
}

    .editStudentTable td, .editStudentTable th {
        padding: 0.5rem 0.25rem
    }

    .editStudentTable tr td:first-of-type {
        text-align: right;
        font-weight: bold;
    }

.react-datepicker__close-icon {
    display: none;
    pointer-events: none;
}

    .react-datepicker__close-icon::after {
        display: none !important;
        pointer-events: none;
    }

.dateTimeContain .react-datepicker-wrapper {
    width: 50%;
}

.dateTimeL.form-control {
    margin-right: 0;
    padding-right: 0;
    border-right: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

    .dateTimeL.form-control:focus {
        box-shadow: -0.15rem 0 0 0.15rem rgba(13,110,253,.25);
    }

.dateTimeR.form-control {
    margin-left: 0;
    padding-left: 0;
    border-left: 0px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 50%;
    display: inline-block;
}

img {
    vertical-align: middle;
}

ul, li {
    list-style: none;
}

.main-bg {
    height: 100vh;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, .4);
    background-blend-mode: multiply;
    background-image: url("https://images.unsplash.com/photo-1569144157581-984dea473e3b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1974&q=80");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

    .main-bg ul {
        padding-top: 120px;
        padding-left: 0px;
    }

.main-btn {
    border: 1px solid black;
    background: #fff;
}

    .main-btn:hover {
        color: #fff;
        background: gray;
        border: 1px solid gray;
    }

    .main-btn:active {
        backdrop-filter: blur(10px);
    }

.fixed-top {
    position: sticky;
    /*top: 0;*/
}

p {
    margin: 0px;
}

.table-bordered-dark {
    border: 1px solid black;
}

.h-100 {
    height: 100%;
}

.vh-100 {
    height: 100vh;
}
text-decoration-none {
    text-decoration: none;
}
.collapse-link {
    color: black;
    background: #fff;
}
.collapse-link:hover {
    background: black;
    color: #fff;
}
.fs-7{
    font-size: 0.5rem;
}